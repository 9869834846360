import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { queryToGraph } from '../helper/graphRequest';
import { getUserGraph } from '../graphql/User';
import _ from 'lodash';
import axios from 'axios';
import { roles } from '../constants/system';
import { toast } from 'react-toastify';

// const baseURL = 'https://carrental-9ijm.onrender.com/users';
const baseURL = 'https://rent-car-backend-ecxw.onrender.com/api';

const initialState = {
  users: [],
  status: 'idle',
  isLoading: false,
  isModalOpen: false,
  error: ''
};

export const fetchUsers = createAsyncThunk('clients/fetchclients', async () => {
  const response = await queryToGraph(getUserGraph());
  if (_.get(response, 'User.isSuccess', false)) {
    return _.get(response, 'User.result', []);
  }
  return _.get(response, 'User.message', '');
});

export const addUser = createAsyncThunk(
  'users/addUser',
  async ({
    name,
    email,
    number,
    rolId,
    license,
    passport,
    idDocument,
    residenceAddress,
    address,
    password
  }) => {
    const response = await axios.post(`${baseURL}/user`, {
      name,
      email,
      number,
      rolId,
      license,
      passport,
      idDocument,
      residenceAddress,
      address,
      password
    });

    return response.data;
  }
);

export const updateUser = createAsyncThunk(
  'users/updateUser',
  async ({
    _id,
    name,
    email,
    number,
    password,
    license,
    passport,
    idDocument,
    residenceAddress,
    address,
    rolId,
    changePsw = false
  }) => {
    try {
      const response = await axios.patch(`${baseURL}/user/${_id}`, {
        name,
        email,
        number,
        password,
        license,
        passport,
        idDocument,
        residenceAddress,
        address,
        rolId,
        changePsw
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.errors || 'Update failed');
    }
  }
);

export const deleteUser = createAsyncThunk(
  'users/deleteUser',
  async ({ userId }) => {
    await fetch(`${baseURL}/user/${userId}`, {
      method: 'DELETE'
    });
    return userId;
  }
);

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    // Add a reducer to reset status after a certain time
    resetStatus: (state) => ({
      ...state,
      status: 'idle'
    }),
    openModal: (state) => ({
      ...state,
      isModalOpen: true
    }),
    closeModal: (state) => ({
      ...state,
      isModalOpen: false
    })
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => ({
        ...state,
        status: 'fetching',
        isLoading: true
      }))
      .addCase(fetchUsers.fulfilled, (state, action) => ({
        ...state,
        status: 'success',
        isLoading: false,
        users: action.payload
      }))
      .addCase(fetchUsers.rejected, (state) => ({
        ...state,
        status: 'error',
        isLoading: false
      }))
      .addCase(addUser.pending, (state) => ({
        ...state,
        isLoading: true,
        status: 'adding'
      }))
      .addCase(addUser.fulfilled, (state, action) => {
        // Check if there are no errors
        if (action.payload.isSuccess) {
          toast.success('Creado!');
          return {
            ...state,
            status: 'added successfully',
            isLoading: false,
            isModalOpen: false,
            users: [action.payload.result, ...state.users],
            error: '' // Clear any previous errors
          };
        }
      })
      .addCase(addUser.rejected, (state) => {
        toast.error('El usuario no pudo ser creado, intente más tarde');
        return {
          ...state,
          status: 'error',
          isLoading: false
        };
      })
      .addCase(updateUser.pending, (state) => ({
        ...state,
        status: 'updating',
        isLoading: true
      }))
      .addCase(updateUser.fulfilled, (state, action) => {
        if (action.payload.isSuccess) {
          toast.success('Usuario actualizado!');
          return {
            ...state,
            status: 'updated successfully',
            isModalOpen: false,
            isLoading: false,
            users: state.users.map((user) =>
              user._id === action.payload.result?._id
                ? { ...action.payload.result }
                : user
            )
          };
        }
      })
      .addCase(updateUser.rejected, (state, action) => {
        toast.error('El usuario no pudo ser actualizado, intente más tarde');
        return {
          ...state,
          status: 'error-updating',
          error: action.error.message
        };
      })
      .addCase(deleteUser.pending, (state) => ({
        ...state,
        status: 'deleting',
        isLoading: true
      }))
      .addCase(deleteUser.fulfilled, (state, action) => {
        const updatedUsers = state.users.map((user) => {
          if (user?._id === action.payload) {
            return {
              ...user,
              status: false // Cambia el valor de status aquí según lo que necesites
            };
          }
          return user;
        });
        return {
          ...state,
          status: 'removed successfully',
          isLoading: false,
          users: updatedUsers
        };
      })
      .addCase(deleteUser.rejected, (state) => ({
        ...state,
        status: 'error-deleting',
        isLoading: false
      }));
  }
});

export const { resetStatus, openModal, closeModal } = usersSlice.actions;
export default usersSlice.reducer;
